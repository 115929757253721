import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";

import Router from "./router";
import i18n from "./translation";

import GlobalStateContext from "./context/globalStateContext";
import UserContext from "./context/userContext";
import { useMediaQuery, useTheme } from "@mui/material";

// Define your types here
interface IGlobalState {
  // define your global state shape here
  [key: string]: any;
}

interface IUser {
  // define your user shape here
  [key: string]: any;
}

const App = () => {
  const [user, setUserContext] = useState<IUser | null>(null);
  const [globalState, setGlobalState] = useState<IGlobalState | null>(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  
  useEffect(() => {
    setGlobalState({...globalState, isDesktop })
  }, [isDesktop])
  
  return (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>
        <UserContext.Provider value={{ user, setUserContext }}>
          <Router />
        </UserContext.Provider>
      </GlobalStateContext.Provider>
    </I18nextProvider>
  </BrowserRouter>
)}
;

ReactDOM.render(<App />, document.getElementById("root"));
