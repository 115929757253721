// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Import for Firebase Authentication
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBPuOE4GFvlkWF39PvBr7cHLHGOb1V0hzo",
  authDomain: "happyrancher-cms.firebaseapp.com",
  projectId: "happyrancher-cms",
  storageBucket: "happyrancher-cms.appspot.com",
  messagingSenderId: "149588833537",
  appId: "1:149588833537:web:7b23f2f4d27becc26f5700",
  measurementId: "G-X7WR5GWQ0H",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app); // Initialize the Firebase auth service
const firestore = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

// Export the Firebase app and the services for use in your app
export { app, analytics, auth, firestore, storage, functions };
