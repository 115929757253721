// import { createContext } from 'react';

// const UserContext = createContext(null);

// export default UserContext;

// UserContext.tsx
import { createContext } from "react";

interface IUser {
  // define your user shape here
  [key: string]: any;
}

const UserContext = createContext<{ user: IUser | null, setUserContext: React.Dispatch<React.SetStateAction<IUser | null>> | null}>({ user: null, setUserContext: null });

export default UserContext;
