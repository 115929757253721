// import { createContext } from "react";

// // const GlobalStateContext = createContext(null);
// const GlobalStateContext = createContext<{ globalState: IGlobalState | null, setGlobalState: React.Dispatch<React.SetStateAction<IGlobalState | null>> | null}>({ globalState: null, setGlobalState: null });

// export default GlobalStateContext;

// GlobalStateContext.tsx
import { createContext } from "react";

interface IGlobalState {
  // define your global state shape here
  [key: string]: any;
}

const GlobalStateContext = createContext<{ globalState: IGlobalState | null, setGlobalState: React.Dispatch<React.SetStateAction<IGlobalState | null>> | null}>({ globalState: null, setGlobalState: null });

export default GlobalStateContext;
