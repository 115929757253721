const routes = [
  {
    path: ["/"],
    exact: true,
    component: "Webstore",
  },
  {
    path: "/checkout/",
    exact: true,
    component: "Checkout",
  },
  {
    path: ["/:collectionId/"],
    exact: true,
    component: "SiteCollection",
  },
  {
    path: "/product/:productId",
    exact: true,
    component: "SiteProduct",
  },
  {
    path: "/confirmation/:orderId",
    exact: true,
    component: "ThankYou",
  },
  {
    path: "/preview",
    exact: true,
    component: "Preview",
  },
];

export default routes;
