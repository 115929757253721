import React, {  lazy, Suspense, useEffect, ComponentType, FC, useContext } from 'react';
import { Switch, Route, Redirect, useHistory, RouteProps } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import routes from './config'; // Adjusted import to default
import { Styles } from '../styles/styles';
import { firestore, auth } from '../firebaseConfig'; // Make sure to import your Firebase auth
import { doc, getDoc } from "firebase/firestore";
import UserContext from '../context/userContext';
import CustomLoader from '../components/Animations/CustomLoader';
import farmerAnimData from "../assets/farmerAnimData.json";
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';

interface RouteItem {
  path: string | string[];
  exact: boolean;
  component: string;
  protected?: boolean;
}

interface PrivateRouteProps extends RouteProps {
  component: ComponentType<any>;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth.currentUser ? (
        <Component {...props} />
      ) : (
        <Redirect to="/landing" />
      )
    }
  />
);

const Router: FC = () => {
  const history = useHistory();
  const { user, setUserContext } = useContext(UserContext)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {

      if (!user) {
        // If no user, nothing happens or you can redirect to landing page
        return;
      }
      // Add your logic here to determine if the user should be redirected to '/admin'
      // For instance, check if the user has an 'admin' role in your user's database document
      try {
        const docRef = doc(firestore, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists() ) {
          // Redirect to admin page only if the user is an admin
          setUserContext && setUserContext(docSnap.data()); 
          // history.push('/admin'); --- NAVIGATES TO ADMIN PAGE IF LOGGED IN - UNCOMMENT
        }
      } catch (error) {
        console.error("Error checking user admin status", error);
        // Handle any errors appropriately
      }
    });
  
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [history]);
  


  return (
    <Suspense fallback={
        <Box
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <CustomLoader animData={farmerAnimData} /> */}
          <CircularProgress />
        </Box>    
    }>
      <Styles />
      <Switch>
        {routes.map((routeItem: RouteItem) => {
          const Component = lazy(() => import(`../pages/${routeItem.component}`));
          const key = Array.isArray(routeItem.path) ? routeItem.path.join('_') : routeItem.path;
          return routeItem.protected ? (
            <PrivateRoute
              key={key}
              path={routeItem.path}
              exact={routeItem.exact}
              component={Component}
            />
          ) : (
            <Route
              key={key}
              path={routeItem.path}
              exact={routeItem.exact}
              component={Component}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

export default Router;


